import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { PuzzleMetadataKey, ACROSS, DOWN, emptyCharGrid, emptyPuzzleMetadata, SlotStructure, SymmetryType } from "./directionsLib";
import { emptyFurnishingsObject, FurnishingType } from "./furnishingsLib";


const SAVE_FORMAT_VERSION = 'sfv4';


/*
Deals with formatting according to https://www.notion.so/puzzleContent-data-format-2b1091e0f0544df883921a214ce0e569?pvs=4.
*/


export function fromPuzzleContentObject(puzzleContent) {

  let { title, author, editor, copyright, publisher, date, startMessage, endMessage, symmetryType, size, grid, clues, furnishings, /*saveFormatVersion*/ } = puzzleContent;
  
  // Handle older saveFormatVersions (current version: sfv2; if sfv1 then furnishings will not be present)
  if (!furnishings) {
    furnishings = emptyFurnishingsObject();
  }

  // Build charGrid
  const { rows, cols } = size;

  let charGrid = [];
  let i = 0;
  for (let r = 0; r < rows; ++r) {
    charGrid.push([]);
    for (let c = 0; c < cols; ++c) {
      const letter = grid[i];
      // Ensure the contents of the square is supported by Crossworthy (currently letters or periods/blackouts only)
      if (/^[A-Z]$/.test(letter)) {
        charGrid[r].push(letter);
      } else if (letter === '.') {
        charGrid[r].push('blackout');
      } else {
        charGrid[r].push('');   // for any unsupported characters, fill with an empty space instead
      }
      ++i;
    }
  }

  // Build clues
  let crossworthyClues = new Map();
  clues.across.forEach(clue => {
    const [slotNumber, clueText] = clue.split(/\. (.*)/);
    crossworthyClues.set(slotNumber + '-' + ACROSS, clueText);
  });
  clues.down.forEach(clue => {
    const [slotNumber, clueText] = clue.split(/\. (.*)/);
    crossworthyClues.set(slotNumber + '-' + DOWN, clueText);
  });

  // Build puzzleMetadata
  const puzzleMetadata = new Map([
    [ PuzzleMetadataKey.TITLE, title ? title : '' ],
    [ PuzzleMetadataKey.AUTHOR, author ? author : '' ],
    [ PuzzleMetadataKey.EDITOR, editor ? editor : '' ],
    [ PuzzleMetadataKey.COPYRIGHT, copyright ? copyright : '' ],
    [ PuzzleMetadataKey.PUBLISHER, publisher ? publisher : '' ],
    [ PuzzleMetadataKey.DATE, date ? date : '' ],
    [ PuzzleMetadataKey.START_MESSAGE, startMessage || '' ],
    [ PuzzleMetadataKey.END_MESSAGE, endMessage || '' ],
    [ PuzzleMetadataKey.SYMMETRY_TYPE, symmetryType || SymmetryType.NONE ],
  ]);

  return {
    charGrid,
    furnishings,
    clues: crossworthyClues,
    puzzleMetadata,
  };
}



/**
 * Returns a JavaScript object representing the board as described at https://www.xwordinfo.com/JSON/.
 * Includes the "size", "grid", "gridnums", "clues", "answers", and metadata fields (e.g. "title", etc.).
 * @param {[[string]]} charGrid 
 * @param {Object} furnishings furnishings object, in a specific format (see Notion)
 * @param {Map(string: string)} clues 
 * @param {Map} puzzleMetadata Map from PuzzleMetadataKey to string, including title, author, etc.
 * @param {SlotStructure?} slotStructure If defined, the slotStructure corresponding to charGrid. If not defined, will re-calculate.
 * @returns {Object}
 */
export function toPuzzleContentObject(charGrid, furnishings, clues, puzzleMetadata, slotStructure) {
  if (!slotStructure) {
    slotStructure = SlotStructure.buildNewSlotStructure(charGrid);
  }

  const size = {
    rows: charGrid.length,
    cols: charGrid[0].length
  };

  let grid = [];
  let gridnums = [];

  let currentNumber = 1;
  for (let r = 0; r < size.rows; ++r) {
    for (let c = 0; c < size.cols; ++c) {
      if (charGrid[r][c] === 'blackout') {
        grid.push('.');
        gridnums.push(0);
      } else {
        grid.push(charGrid[r][c]);
        if (r === 0 || c === 0 || charGrid[r-1][c] === 'blackout' || charGrid[r][c-1] === 'blackout') {
          gridnums.push(currentNumber);
          currentNumber++;
        } else {
          gridnums.push(0);
        }
      }
    }
  }

  let JSclues = {
    across: [],
    down: []
  };
  let answers = {
    across: [],
    down: []
  };

  clues.forEach((clueText, slotName) => {
    const [slotNumber, slotDirection] = slotName.split('-');
    
    let clue = slotNumber + '. ' + clueText;
    if (slotDirection === ACROSS) {
      JSclues.across.push(clue);
      answers.across.push(slotStructure.getWordInSlotName(slotName));
    } else {
      JSclues.down.push(clue);
      answers.down.push(slotStructure.getWordInSlotName(slotName));
    }
  });

  return {
    title: puzzleMetadata.get(PuzzleMetadataKey.TITLE),
    author: puzzleMetadata.get(PuzzleMetadataKey.AUTHOR),
    editor: puzzleMetadata.get(PuzzleMetadataKey.EDITOR),
    copyright: puzzleMetadata.get(PuzzleMetadataKey.COPYRIGHT),
    publisher: puzzleMetadata.get(PuzzleMetadataKey.PUBLISHER),
    date: puzzleMetadata.get(PuzzleMetadataKey.DATE),
    startMessage: puzzleMetadata.get(PuzzleMetadataKey.START_MESSAGE) || '',
    endMessage: puzzleMetadata.get(PuzzleMetadataKey.END_MESSAGE) || '',
    symmetryType: puzzleMetadata.get(PuzzleMetadataKey.SYMMETRY_TYPE) || SymmetryType.ROTATIONAL,
    size: size,
    grid: grid,
    gridnums: gridnums,
    clues: JSclues,
    answers: answers,
    furnishings: furnishings,
    saveFormatVersion: SAVE_FORMAT_VERSION,
  };
}



/**
 * Returns an empty puzzleContent object of the form required for API call, e.g. for saving a newly-created board.
 * @param {{numRows?, numCols?, title?}} options optional { numRows, numCols, title }
 */
export function emptyPuzzleContentObject(options) {
  if (!options) {
    options = {};
  }

  const {
    numRows = 15,
    numCols = 15,
    title = '',
    blackouts = [],  // list of locs
    initialChars = null,  // [ { loc, char } ]
  } = options;

  const charGrid = emptyCharGrid(numRows, numCols);
  if (blackouts) {
    for (const loc of blackouts) {
      charGrid[loc[0]][loc[1]] = 'blackout';
    }
  }
  if (initialChars) {
    for (const { loc, char } of initialChars) {
      charGrid[loc[0]][loc[1]] = char;
    }
  }
  const furnishings = emptyFurnishingsObject();
  const clues = new Map(SlotStructure.buildNewSlotStructure(charGrid).slotNames.map(slotName => { return [slotName, '']; }));
  const metadata = emptyPuzzleMetadata();
  metadata.set(PuzzleMetadataKey.TITLE, title);

  return toPuzzleContentObject(charGrid, furnishings, clues, metadata);
}







export function getPrintablePDF({
  gridPngData,   // optional PNG image data: if not given, will create a table by itself (though some furnishings may be missing)
  puzzleMetadata,
  charGrid,
  furnishings,
  clues,
  options = {
    includeAnswers: false,
    includeClues: true,
    clueFont: 'Helvetica',
    clueFontSize: 10,
  }
}) {
  const { includeAnswers, includeClues, clueFontSize, clueFont } = options;
  if (!includeClues) clues = undefined;

  const doc = new jsPDF({ format: 'letter', unit: 'in' });

  // Define constants to aid layout
  const MARGINS = {
    left: 0.5,
    right: 0.5, 
    top: 0.5,
    bottom: 0.5
  };
  const numPillars = 5;    // "pillars" are the columns of clues
  const interPillarSpacing = 0.1;
  const pillarWidth = (8.5 - MARGINS.left - MARGINS.right - (numPillars-1) * interPillarSpacing) / numPillars;
  const numPillarsSpannedByGrid = clues ? 3 : 5;   // TODO - change this number for weirdly-shaped grids
  const numGridRows = charGrid.length;
  const numGridCols = charGrid[0].length;
  const gridWidth = numPillarsSpannedByGrid * pillarWidth + (numPillarsSpannedByGrid-1) * interPillarSpacing;
  const cellSize = gridWidth / numGridCols;
  const gridHeight = cellSize * numGridRows;
  const gridFontSize = cellSize * 45;
  const cornerNumberFontSize = cellSize * 24;
  const headerWidth = (numPillarsSpannedByGrid === numPillars ? numPillars : numPillars - numPillarsSpannedByGrid) * pillarWidth + interPillarSpacing;
  const paragraphSpacing = 0.09;  // e.g. space between two clues
  const lineSpacing = 0.02;   // e.g. space between two lines of the same clue
  const ptPerInch = 72;
  const titleFontSize = 16;
  const maxClueNumberWidth = clueFontSize / ptPerInch * 1.8;  // pretty arbitrary/empirical
  

  if (gridPngData) {
    // There's a png image given for the grid, so we'll use that
    doc.addImage(gridPngData, 'png', 8.5 - MARGINS.right - gridWidth, MARGINS.top, gridWidth, gridHeight);

  } else {
    // There's no gridImgData, so we'll lay out our own table using jsPdf
    const gridTable = [];
    for (let r = 0; r < numGridRows; ++r) {
      gridTable.push([]);
      for (let c = 0; c < charGrid[0].length; ++c) {
        gridTable[r].push({
          content: !includeAnswers || charGrid[r][c] === 'blackout' ? '' : charGrid[r][c],
          styles: {
            fillColor: charGrid[r][c] === 'blackout' ? 0 : (furnishings?.[FurnishingType.COLOR]?.[JSON.stringify([r,c])] || 255),
            lineColor: 0,
            lineWidth: 0.006,
            halign: 'center',
            valign: 'bottom',
            cellPadding: 0,
            cellWidth: cellSize,
            minCellHeight: cellSize,
            fontSize: gridFontSize,
            textColor: 0,
          },
        });
      }
    }
    var cornerNumber = 1;
    doc.autoTable({
      body: gridTable,
      margin: { top: MARGINS.top, left: 8.5 - MARGINS.right - gridWidth, bottom: 0, right: 0 },
      didDrawCell: (hookData) => {
        const r = hookData.row.index;
        const c = hookData.column.index;
        if (
          charGrid[r][c] !== 'blackout' &&
          (r === 0 || c === 0 || charGrid[r-1][c] === 'blackout' || charGrid[r][c-1] === 'blackout')
        ) {
          // Draw corner number and increment
          doc.setFontSize(cornerNumberFontSize);
          doc.text(cornerNumber.toString(), hookData.cursor.x + cellSize/52, hookData.cursor.y + cellSize/72, { baseline: 'top' });
          ++cornerNumber;
        }
      },
    });
  }

  // Let's draw our logo (the squares part)
  // Define all the necessary parameters: top, left, right, theta, overlap_h, and overlap_v can all be changed independently to tinker
  const LOGO = {
    top: MARGINS.top + gridHeight + 0.05*pillarWidth, // y-coordinate of top of bounding box
    left: 8.5 - MARGINS.right - pillarWidth*0.9,          // x-coordinate of left of bounding box
    right: 8.5 - MARGINS.right - pillarWidth*0.75,     // x-coordinate of right of bounding box
    theta: 25 * Math.PI / 180,                        // angle of the crossworthy squares in the logo, in radians
    overlap_h: 0.3,                                   // fractional overlap (horizontal) of the black square over the white square
    overlap_v: 0.45,                                  // fractional overlap (vertical) of the black square over the white square
  };
  // Calculate convenience parameters
  LOGO.squareLength = Math.sin(LOGO.theta) * (LOGO.right - LOGO.left) / (Math.cos(LOGO.theta)*Math.sin(LOGO.theta) + 1 - LOGO.overlap_v);
  LOGO.bottom = LOGO.top + LOGO.squareLength*Math.sin(LOGO.theta) + LOGO.squareLength/Math.cos(LOGO.theta);

  // Draw black square
  var {x1, y1, x2, y2} = {
    x1: LOGO.left + LOGO.squareLength/2*Math.sin(LOGO.theta),
    y1: LOGO.bottom - LOGO.squareLength*Math.sin(LOGO.theta) - LOGO.squareLength/2*Math.cos(LOGO.theta),
    x2: LOGO.left + LOGO.squareLength*Math.cos(LOGO.theta) + LOGO.squareLength/2*Math.sin(LOGO.theta),
    y2: LOGO.bottom - LOGO.squareLength/2*Math.cos(LOGO.theta),
  };
  doc.setLineWidth(LOGO.squareLength).setDrawColor(10).line(x1, y1, x2, y2);
  // Draw white square
  x1 = LOGO.right - LOGO.squareLength*Math.cos(LOGO.theta) - LOGO.squareLength*(1-LOGO.overlap_v)*Math.sin(LOGO.theta);
  y1 = LOGO.top + LOGO.squareLength*(1-LOGO.overlap_v)*Math.cos(LOGO.theta);
  x2 = LOGO.right - LOGO.squareLength*Math.cos(LOGO.theta);
  y2 = LOGO.top;
  var x3 = LOGO.right;
  var y3 = LOGO.top + LOGO.squareLength*Math.sin(LOGO.theta);
  var x4 = LOGO.right - LOGO.squareLength*Math.sin(LOGO.theta);
  var y4 = LOGO.bottom - LOGO.squareLength*Math.tan(LOGO.theta)*Math.sin(LOGO.theta);
  var x5 = LOGO.right - (LOGO.squareLength*(1-LOGO.overlap_h+Math.tan(LOGO.theta))) * Math.cos(LOGO.theta);
  var y5 = LOGO.bottom - (LOGO.squareLength*(1-LOGO.overlap_h+Math.tan(LOGO.theta))) * Math.sin(LOGO.theta);
  doc.setLineWidth(0.01).setDrawColor(10).line(x1, y1, x2, y2).line(x2,y2,x3,y3).line(x3,y3,x4,y4).line(x4,y4,x5,y5);
  // Draw the "1": x1, y1 are the anchor coordinates for top-right of the letter
  x1 = LOGO.right - LOGO.squareLength*Math.cos(LOGO.theta) - LOGO.squareLength*0.06;  // last term empirically determined
  y1 = LOGO.top + LOGO.squareLength*0.1;   // last term empirically determined
  doc.setFont('times', 'bold').setFontSize(LOGO.squareLength*29).setTextColor(10);
  doc.text("1", x1, y1, { angle: LOGO.theta*180/Math.PI, rotationDirection: 0, baseline: 'top', align: 'left' });

  // Write the "Crossworthy Construct" part of the brand logo
  doc.setFont('courier', 'normal').setFontSize(pillarWidth*7).setTextColor(120);
  doc.text('Crossworthy', 8.5-MARGINS.right-pillarWidth*0.08, MARGINS.top+gridHeight+lineSpacing, { baseline: 'top', align: 'right' });
  doc.setTextColor(70).text('Construct', 8.5-MARGINS.right, MARGINS.top+gridHeight+lineSpacing+pillarWidth*0.07, { baseline: 'top', align: 'right' });



  // Define running cursor in document to track location
  var x = MARGINS.left;
  var y = numPillarsSpannedByGrid === numPillars ? MARGINS.top + gridHeight + interPillarSpacing : MARGINS.top;

  /** Writes given text within maxWidth and wraps if necessary. Returns the new y value at the bottom of the last written line. */
  function writeWrappedText(text, maxWidth, x, y, fontSize) {
    // y cursor starts at top of text
    doc.setFontSize(fontSize);
    y += fontSize / ptPerInch;

    const textLines = doc.splitTextToSize(text, maxWidth);
    doc.text(textLines[0], x, y);
    for (let i = 1; i < textLines.length; ++i) {
      y += lineSpacing + fontSize / ptPerInch;
      doc.text(textLines[i], x, y);
    }

    return y; // returns y cursor at bottom of last written line
  }

  // Header
  if (puzzleMetadata.get(PuzzleMetadataKey.DATE)) {
    const dateFontSize = 12;
    doc.setFont(clueFont);
    y = writeWrappedText(puzzleMetadata.get(PuzzleMetadataKey.DATE), headerWidth, x, y, dateFontSize);
  }

  if (puzzleMetadata.get(PuzzleMetadataKey.TITLE)) {
    doc.setFont(clueFont, 'bold');
    y = writeWrappedText(puzzleMetadata.get(PuzzleMetadataKey.TITLE), headerWidth, x, y, titleFontSize);
    y += paragraphSpacing;
    doc.setDrawColor(40).setLineWidth(1/ptPerInch).line(x, y, x + headerWidth, y);
  }

  if (puzzleMetadata.get(PuzzleMetadataKey.AUTHOR)) {
    const authorFontSize = 12;
    doc.setFont(clueFont, 'normal');
    y = writeWrappedText('By ' + puzzleMetadata.get(PuzzleMetadataKey.AUTHOR), headerWidth, x, y, authorFontSize);
    y += lineSpacing;
  }
  if (puzzleMetadata.get(PuzzleMetadataKey.EDITOR)) {
    const editorFontSize = 10;
    doc.setFont(clueFont, 'italic');
    y = writeWrappedText('Edited by ' + puzzleMetadata.get(PuzzleMetadataKey.EDITOR), headerWidth, x, y, editorFontSize);
    y += paragraphSpacing;
  }
  const headerBottom = y;

  // Clues
  if (clues) {

    var pillarIndex = 1;   // starts at 1
    var page = 1;          // starts at 1

    function writeClue(x, y, clueNumber, clueText, pillarIndex, page) {
      // Write number
      doc.setFont(clueFont, 'bold').setFontSize(clueFontSize);
      doc.text(clueNumber, x, y + clueFontSize/ptPerInch);
      // Write clue
      doc.setFont(undefined, 'normal');
      y = writeWrappedText(clueText, pillarWidth - maxClueNumberWidth, x+maxClueNumberWidth, y, clueFontSize);
      // Advance cursor; wrap pillars/page if necessary
      y += paragraphSpacing;
      if (y >= 11 - MARGINS.bottom - 2*clueFontSize/ptPerInch - lineSpacing) {  // budget for two more lines
        if (pillarIndex === numPillars) {
          // Necessitates new page
          page += 1;
          pillarIndex = 1;
          doc.addPage();
          x = MARGINS.left;
          y = MARGINS.top;
        } else {
          // Necessitates pillar wrap
          pillarIndex += 1;
          x = MARGINS.left + (pillarIndex - 1) * (pillarWidth + interPillarSpacing);
          if (page === 1) {
            if (pillarIndex <= numPillars - numPillarsSpannedByGrid) {
              y = headerBottom;
            } else {
              if (numPillars === numPillarsSpannedByGrid) {  // grid spans entire row
                y = headerBottom;
              } else {
                y = MARGINS.top + gridHeight + paragraphSpacing;
              }
            }
            if (pillarIndex === numPillars) {   // budget for the crossworthy logo in the last pillar
              y += pillarWidth*0.25;
            }
          } else {
            y = MARGINS.top;
          }
        }
      }
      
      return { x, y, pillarIndex, page };
    }

    // ACROSS
    doc.setFont(clueFont, 'bold');
    y = writeWrappedText('ACROSS', pillarWidth, x, y, clueFontSize);
    y += paragraphSpacing;
    for (let [slotName, clueText] of clues) {
      const [slotNumber, slotDirection] = slotName.split('-');
      if (slotDirection === ACROSS) {
        ({x, y, pillarIndex, page } = writeClue(x, y, slotNumber, clueText, pillarIndex, page));
      }
    }
    // DOWN
    y += paragraphSpacing;
    if (y >= 11 - MARGINS.bottom - 5*clueFontSize/ptPerInch - 4*paragraphSpacing) {
      // Necessitates pillar wrap
      pillarIndex += 1;
      x = MARGINS.left + (pillarIndex - 1) * (pillarWidth + interPillarSpacing);
      if (page === 1) {
        if (pillarIndex <= numPillars - numPillarsSpannedByGrid) {
          y = headerBottom;
        } else {
          if (numPillars === numPillarsSpannedByGrid) {  // grid spans entire row
            y = headerBottom;
          } else {
            y = MARGINS.top + gridHeight + paragraphSpacing;
          }
        }
        if (pillarIndex === numPillars) {   // budget for the crossworthy logo in the last pillar
          y += pillarWidth*0.25;
        }
      } else {
        y = MARGINS.top;
      }
    }
    doc.setFont(clueFont, 'bold');
    y = writeWrappedText('DOWN', pillarWidth, x, y, clueFontSize);
    y += paragraphSpacing;
    for (let [slotName, clueText] of clues) {
      const [slotNumber, slotDirection] = slotName.split('-');
      if (slotDirection === DOWN) {
        ({x, y, pillarIndex, page} = writeClue(x, y, slotNumber, clueText, pillarIndex, page));
      }
    }

  }

  return doc;
}


/* PuzWriter class from Phil http://www.keiranking.com/apps/phil/ */
class PuzWriter {
  constructor() {
    this.buf = []
  }

  pad(n) {
    for (var i = 0; i < n; i++) {
      this.buf.push(0);
    }
  }

  writeShort(x) {
    this.buf.push(x & 0xff, (x >> 8) & 0xff);
  }

  setShort(ix, x) {
    this.buf[ix] = x & 0xff;
    this.buf[ix + 1] = (x >> 8) & 0xff;
  }

  writeString(s) {
    if (s === undefined) s = '';
    for (var i = 0; i < s.length; i++) {
      var cp = s.codePointAt(i);
      if (cp < 0x100 && cp > 0) {
        this.buf.push(cp);
      } else {
        console.log('string "' + s + '" has non-ISO-8859-1 codepoint at offset ' + i);
        this.buf.push('?'.codePointAt(0));
      }
      if (cp >= 0x10000) i++;   // advance by one codepoint
    }
    this.buf.push(0);
  }

  writeHeader(json) {
    this.pad(2); // placeholder for checksum
    this.writeString('ACROSS&DOWN');
    this.pad(2); // placeholder for cib checksum
    this.pad(8); // placeholder for masked checksum
    this.version = '1.3';
    this.writeString(this.version);
    this.pad(2); // probably extra space for version string
    this.writeShort(0);  // scrambled checksum
    this.pad(12);  // reserved
    this.w = json.size.cols;
    this.h = json.size.rows;
    this.buf.push(this.w);
    this.buf.push(this.h);
    this.numClues = json.clues.across.length + json.clues.down.length;
    this.writeShort(this.numClues);
    this.writeShort(1);  // puzzle type
    this.writeShort(0);  // scrambled tag
  }

  writeFill(json) {
    const grid = json.grid;
    const BLACK_CP = '.'.codePointAt(0);
    this.solution = this.buf.length;
    for (let i = 0; i < grid.length; i++) {
      this.buf.push(grid[i].codePointAt(0));  // Note: assumes grid is ISO-8859-1
    }
    this.grid = this.buf.length;
    for (let i = 0; i < grid.length; i++) {
      var cp = grid[i].codePointAt(0);
      if (cp !== BLACK_CP) cp = '-'.codePointAt(0);
      this.buf.push(cp);
    }
  }

  writeStrings(json) {
    this.stringStart = this.buf.length;
    this.writeString(json.title);
    this.writeString(json.author);
    this.writeString(json.copyright);
    const across = json.clues.across;
    const down = json.clues.down;
    var clues = [];
    for (let i = 0; i < across.length; i++) {
      const sp = across[i].split('. ');
      clues.push([2 * parseInt(sp[0]), sp.slice(1).join('. ')]);
    }
    for (let i = 0; i < down.length; i++) {
      const sp = down[i].split('. ');
      clues.push([2 * parseInt(sp[0]) + 1, sp.slice(1).join('. ')]);
    }
    clues.sort((a, b) => a[0] - b[0]);
    for (let i = 0; i < clues.length; i++) {
      this.writeString(clues[i][1]);
    }
    this.writeString(json.notepad);
  }

  checksumRegion(base, len, cksum) {
    for (let i = 0; i < len; i++) {
      cksum = (cksum >> 1) | ((cksum & 1) << 15);
      cksum = (cksum + this.buf[base + i]) & 0xffff;
    }
    return cksum;
  }

  strlen(ix) {
    var i = 0;
    while (this.buf[ix + i]) i++;
    return i;
  }

  checksumStrings(cksum) {
    let ix = this.stringStart;
    for (let i = 0; i < 3; i++) {
      const len = this.strlen(ix);
      if (len) {
        cksum = this.checksumRegion(ix, len + 1, cksum);
      }
      ix += len + 1;
    }
    for (let i = 0; i < this.numClues; i++) {
      const len = this.strlen(ix);
      cksum = this.checksumRegion(ix, len, cksum);
      ix += len + 1;
    }
    if (this.version === '1.3') {
      const len = this.strlen(ix);
      if (len) {
        cksum = this.checksumRegion(ix, len + 1, cksum);
      }
      ix += len + 1;
    }
    return cksum;
  }

  setMaskedChecksum(i, maskLow, maskHigh, cksum) {
    this.buf[0x10 + i] = maskLow ^ (cksum & 0xff);
    this.buf[0x14 + i] = maskHigh ^ (cksum >> 8);
  }

  computeChecksums() {
    var c_cib = this.checksumRegion(0x2c, 8, 0);
    this.setShort(0xe, c_cib);
    var cksum = this.checksumRegion(this.solution, this.w * this.h, c_cib);
    cksum = this.checksumRegion(this.grid, this.w * this.h, cksum);
    cksum = this.checksumStrings(cksum);
    this.setShort(0x0, cksum);
    this.setMaskedChecksum(0, 0x49, 0x41, c_cib);
    var c_sol = this.checksumRegion(this.solution, this.w * this.h, 0);
    this.setMaskedChecksum(1, 0x43, 0x54, c_sol);
    var c_grid = this.checksumRegion(this.grid, this.w * this.h, 0);
    this.setMaskedChecksum(2, 0x48, 0x45, c_grid);
    var c_part = this.checksumStrings(0);
    this.setMaskedChecksum(3, 0x45, 0x44, c_part);
  }

  toPuz(json) {
    this.writeHeader(json);
    this.writeFill(json);
    this.writeStrings(json);
    this.computeChecksums();
    return new Uint8Array(this.buf);
  }
}


/**
 * Returns a Uint8Array of the .puz representation for the given crossword.
 * @param {Object} data
 */
export function getPuzFile({
  puzzleMetadata,
  charGrid,
  clues,
}) {
  var serializedPuzzleContent = toPuzzleContentObject(charGrid, [], clues, puzzleMetadata);
  // Leaving clues blank is unpalatable for AcrossLite - convert those to 'blank clue'
  for (let i = 0; i < serializedPuzzleContent.clues.across.length; ++i) {
    if (/^[0-9]+\. $/.test(serializedPuzzleContent.clues.across[i])) {
      serializedPuzzleContent.clues.across[i] = serializedPuzzleContent.clues.across[i] + '(blank clue)';
    }
  }
  for (let i = 0; i < serializedPuzzleContent.clues.down.length; ++i) {
    if (/^[0-9]+\. $/.test(serializedPuzzleContent.clues.down[i])) {
      serializedPuzzleContent.clues.down[i] = serializedPuzzleContent.clues.down[i] + '(blank clue)';
    }
  }
  // Also, leaving grid tiles blank is unpalatable for AcrossLite - convert those to ?s
  for (let i = 0; i < serializedPuzzleContent.grid.length; ++i) {
    if (serializedPuzzleContent.grid[i] === '') {
      serializedPuzzleContent.grid[i] = '?';
    }
  }
  return new PuzWriter().toPuz(serializedPuzzleContent);
}


/**
 * Returns a jsPDF object of a PDF formatted according to NYT submission guidelines.
 * @param {Object} data
 */
export function getNYTsubmissionPDF({
  author,
  addressLine1,
  addressLine2,
  email,
  gridPngData,  // optional - otherwise will use autoTable, similar to getPrintablePDF
  charGrid,
  clues,
  furnishings,
}) {
  const slotStructure = SlotStructure.buildNewSlotStructure(charGrid);

  const doc = new jsPDF();

  // Contact info in header
  doc.setFont('times');
  doc.setFontSize(12);
  var y = 18;
  if (author) {
    doc.text(author, 105, y, 'center');
    y += 7;
  }
  if (addressLine1) {
    doc.text(addressLine1, 105, y, 'center');
    y += 7;
  }
  if (addressLine2) {
    doc.text(addressLine2, 105, y, 'center');
    y += 7;
  }
  if (email) {
    doc.text(email, 105, y, 'center');
  }


  const cellSize = Math.min(140 / charGrid[0].length, 150 / charGrid.length);  // in mm; A4 paper is 210x297 mm

  if (gridPngData) {
    // There's a png image given for the grid, so we'll use that
    doc.addImage(gridPngData, 'png', (210 - cellSize*charGrid[0].length) / 2, 50, cellSize*charGrid[0].length, cellSize*charGrid.length);

  } else {
    // Build table of objects understood by autoTable
    const gridTable = [];

    for (let r = 0; r < charGrid.length; ++r) {
      gridTable.push([]);
      for (let c = 0; c < charGrid[0].length; ++c) {
        gridTable[r].push({
          content: charGrid[r][c] === 'blackout' ? '' : charGrid[r][c],
          styles: {
            fillColor: charGrid[r][c] === 'blackout' ? 0 : (furnishings?.[FurnishingType.COLOR]?.[JSON.stringify([r,c])] || 255),
            lineColor: 0,
            lineWidth: 0.15,
            halign: 'center',
            valign: 'bottom',
            cellPadding: 0,
            cellWidth: cellSize,
            minCellHeight: cellSize,
            fontSize: cellSize * 1.75,
            font: 'times',
            fontStyle: 'bold',
            textColor: 0,
          },
        });
      }
    }
    var cornerNumber = 1;
    doc.autoTable({
      body: gridTable,
      margin: (210 - cellSize*charGrid[0].length) / 2,   // center table in page
      startY: 50,
      didDrawCell: (hookData) => {
        const r = hookData.row.index;
        const c = hookData.column.index;
        if (
          charGrid[r][c] !== 'blackout' &&
          (r === 0 || c === 0 || charGrid[r-1][c] === 'blackout' || charGrid[r][c-1] === 'blackout')
        ) {
          // Draw corner number and increment
          doc.setFontSize(cellSize);
          doc.text(cornerNumber.toString(), hookData.cursor.x + cellSize/12, hookData.cursor.y + cellSize/72, { baseline: 'top' });
          doc.setFontSize(12);
          ++cornerNumber;
        }
      },
    });
  }


  // Clues
  const MARGINS = { top: 20, bottom: 20, left: 15, right: 15, middleDivider: 7 };
  const LINE_SPACING = { newParagraph: 10, newLine: 7 };
  doc.addPage();
  y = MARGINS.top;

  function writeClues(filteredClues, y) {
    for (let [slotName, clueText] of filteredClues) {
      const slotNumber = slotName.split('-')[0];
      // Perhaps clue text is long enough to be broken up into multiple lines
      const clueLines = doc.splitTextToSize(slotNumber + ' ' + clueText, 130-MARGINS.left-MARGINS.middleDivider);

      // Write first clue and the answer word
      doc.text(clueLines[0], MARGINS.left, y);
      doc.text(slotStructure.getWordInSlotName(slotName).toUpperCase(), 130, y);

      // Write following lines of clue text if they exist
      for (let i = 1; i < clueLines.length; ++i) {
        y += LINE_SPACING.newLine;
        doc.text(clueLines[1], MARGINS.left, y);
      }

      y += LINE_SPACING.newParagraph;   // advance 'cursor' to next word location
      
      // New page if necessary
      if (y >= 297 - MARGINS.bottom - LINE_SPACING.newParagraph - LINE_SPACING.newLine) {
        doc.addPage();
        y = MARGINS.top;
      }
    }

    return y;
  }

  doc.text('ACROSS', MARGINS.left, y);
  y += LINE_SPACING.newParagraph;
  y = writeClues(new Map([...clues].filter(([k, v]) => k.split('-')[1] === ACROSS)), y);

  if (y >= 297 - MARGINS.bottom - 3*LINE_SPACING.newParagraph - 2*LINE_SPACING.newLine) {
    doc.addPage();
    y = MARGINS.top;
  } else {
    y += LINE_SPACING.newParagraph + LINE_SPACING.newLine;
  }
  doc.text('DOWN', MARGINS.left, y);
  y += LINE_SPACING.newParagraph;
  writeClues(new Map([...clues].filter(([k, v]) => k.split('-')[1] === DOWN)), y);


  return doc;

}






