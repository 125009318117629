import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Popover, Modal, Row, Col, Form, FormControl, FormLabel, FormCheck, Tooltip, Badge } from 'react-bootstrap';
import { BsBoxArrowUpRight, BsPeopleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { TbTools } from 'react-icons/tb';
import './ConstructorToolbar.css';
import { useCharGrid, usePuzzlePreferences, useGhostChars, usePuzzleMetadata, usePuzzleId,
  useSavePuzzle, usePuzzleInteractionStatus, PuzzleInteractionStatus, useClues, useAutofill, useLiveMode,
  useChangeCharGridDimensions, useUpdateCharGridAtLocs, useRequestNewBlackouts, PuzzlePreferenceKey, usePuzzleVersioning, useFurnishings } from '../BoardInteractionContext';
import KeyboardEventHandler from '../../../libs/keyboardLib';
import Board from '../../board/Board';
import MenuButton, { ShortcutDefinition } from '../../reusable/MenuButton';
import WarningOptions from './WarningOptions';
import KeyboardShortcutOptions from './KeyboardShortcutOptions';
import SaveAsModal from './SaveAsModal';
import SharePuzzleModal from './SharePuzzleModal';
import { BoardStyle } from '../../../libs/formatLib';
import { AUTOFILL_STATUS } from '../../../libs/autofillLib';
import LoaderButton from '../../reusable/LoaderButton';
import { useAppContext } from '../../../App';
import AddToCollectionModal from './AddToCollectionModal';
import { FurnishingType } from '../../../libs/furnishingsLib';
import ExportModal from './ExportModal';
import { PuzzleMetadataKey, SymmetryType } from '../../../libs/directionsLib';



function isGridDimensionValid(inputRowsOrCols) {
  const parsedNum = parseInt(inputRowsOrCols, 10);
  if (!isNaN(parsedNum)) {
    if (parsedNum >= 3 && parsedNum <= 31) {
      return true;
    }
  }
  return false;
}


const warningsExampleChargrid = [
  ['J', '', 'blackout', 'blackout', 'blackout'],
  ['E', '', 'D', '', 'D'],
  ['S', '', 'U', '', 'U'],
  ['S', '', 'P', '', 'P'],
  ['E', '', 'E', '', 'E'],
];



/**
 * Includes all the Buttons on top of the Board and the functionalities they're repsonsible for, including blackout refreshing and puzzle saving.
 */
export default function ConstructorToolbar({...props}) {

  // Hook into BoardInteractionContext
  const puzzleId = usePuzzleId();
  const charGrid = useCharGrid();
  const { ghostChars, acceptGhostChars } = useGhostChars();
  const { requestAutofill, cancelAutofill, autofillStatus, continuouslyAutofill, turnOnContinuousAutofill, turnOffContinuousAutofill } = useAutofill();
  const { clues } = useClues();
  const { furnishings, makeOuterBlackoutsInvisible, removeAllInvisibilityFurnishings } = useFurnishings();
  const { getPuzzlePreference, setPuzzlePreference } = usePuzzlePreferences();
  const { puzzleMetadata, setPuzzleMetavalue } = usePuzzleMetadata();
  const { requestPuzzleSave, isSaving, isSaved } = useSavePuzzle();
  const { puzzleInteractionStatus, isScratch } = usePuzzleInteractionStatus();
  const { inLiveMode, liveModeIsConnecting, liveModeIsTimedOut, liveConnectionInfo, enterLiveMode, exitLiveMode } = useLiveMode();
  const changeCharGridDimensions = useChangeCharGridDimensions();
  const updateCharGridAtLocs = useUpdateCharGridAtLocs();
  const requestNewBlackouts = useRequestNewBlackouts();
  const { requestUndo, requestRedo } = usePuzzleVersioning();
  const { currentStatusMessage } = usePuzzleInteractionStatus();

  const navigate = useNavigate();

  const { isAuthenticated, windowWidth, brandIsShowing, showLoginPage } = useAppContext();

  // Convenience variables
  const numRows = charGrid.length;
  const numCols = charGrid[0].length;
  const outerBlackoutsAreHidden = Object.values(furnishings[FurnishingType.INVISIBILITY] || {}).some(v => v);
  const numLiveConnections = liveConnectionInfo ? Object.keys(liveConnectionInfo).length : 0;
  const maxToolbarCollaborators = windowWidth >= 500 ? 4 : 1;

  // "Save as" modal
  const [saveAsModalShowing, setSaveAsModalShowing] = useState(false);
  const saveAsModal = (
    <SaveAsModal
      show={saveAsModalShowing}
      onHide={() => setSaveAsModalShowing(false)}
      puzzleData={{ charGrid, furnishings, clues, puzzleMetadata }}
    />
  );

  // "Add to collection" modal
  const [showAddToCollectionModal, setShowAddToCollectionModal] = useState(false);
  const addToCollectionModal = <AddToCollectionModal puzzleId={puzzleId} show={showAddToCollectionModal} onHide={() => setShowAddToCollectionModal(false)} />;

  // "Export" modal
  const [showExportModal, setShowExportModal] = useState(false);
  const exportModal = <ExportModal show={showExportModal} onHide={() => setShowExportModal(false)} openShareModal={() => {
    setShowExportModal(false);
    setSharePuzzleModalShowing(true);
  }} />;


  // Configure warnings modal
  const [warningsModalShowing, setWarningsModalShowing] = useState(false);
  const warningsExampleBoardStyle = BoardStyle.default()
    .withAdditionalStyledLocs([[2,0], [2,1], [2,2], [2,3], [2,4]], getPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_NO_SUGGESTIONS))
    .withAdditionalStyledLocs([[0, 0], [0, 1]], getPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_SHORT_WORDS))
    .withAdditionalStyledLocs([[1,2], [2,2], [3,2], [4,2], [1,4], [2,4], [3,4], [4,4]], getPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_DUPLICATE_WORDS));

  const warningsModal = (
    <Modal show={warningsModalShowing} onHide={() => setWarningsModalShowing(false)}>
      <Modal.Header className='teal-1-bkgd' closeButton><strong>Warnings</strong></Modal.Header>
      <Modal.Body className='text-center'>
        <div className='text-muted fst-italic mb-3'>Choose the style for each warning type. An example is shown on the left.</div>
        <Row className='m-2'>
          <Col className='p-1 my-auto' xs={5}>
            <Board
              charGrid={warningsExampleChargrid}
              puzzleInteractionStatus={PuzzleInteractionStatus.STATUESQUE}
              boardStyle={warningsExampleBoardStyle}
            />
          </Col>
          <Col className='p-0 my-auto' xs={7}>
            <WarningOptions />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );


  // Keyboard shortcuts modal
  const [keyboardShortcutsModalShowing, setKeyboardShortcutsModalShowing] = useState(false);
  const keyboardShortcutsModal = (
    <Modal show={keyboardShortcutsModalShowing} onHide={() => setKeyboardShortcutsModalShowing(false)}>
      <Modal.Header className='teal-1-bkgd' closeButton><strong>Keyboard Shortcuts Reference</strong></Modal.Header>
      <Modal.Body>
        <KeyboardShortcutOptions />
      </Modal.Body>
    </Modal>
  );


  // Share modal
  const [sharePuzzleModalShowing, setSharePuzzleModalShowing] = useState(false);
  const shareModal = (
    <SharePuzzleModal
      puzzleId={puzzleId}
      show={sharePuzzleModalShowing}
      onHide={() => setSharePuzzleModalShowing(false)}
    />
  );


  // Save and play modal
  const [saveAndPlayModalShowing, setSaveAndPlayModalShowing] = useState(false);
  const [saveAndPlaySpinning, setSaveAndPlaySpinning] = useState(false);  // true when in saving/loading state
  const saveAndPlayModal = (
    <Modal show={saveAndPlayModalShowing} onShow={() => setSaveAndPlaySpinning(false)} onHide={() => setSaveAndPlayModalShowing(false)}>
      <Modal.Header className='teal-3-bkgd' closeButton>
        <strong>Save puzzle</strong>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <div>You must save your new changes in order to play this puzzle.</div>
        <div className='d-flex mt-3'>
          <LoaderButton
            className='ms-auto'
            variant='info'
            size='sm'
            isLoading={saveAndPlaySpinning}
            onClick={() => {
              setSaveAndPlaySpinning(true);
              requestPuzzleSave(() => {
                window.open(`/play/${puzzleId}`, '_blank', 'noreferrer');
                setSaveAndPlayModalShowing(false);
              });
            }}
          >
            Save and play
            <BsBoxArrowUpRight className='ms-2' />
          </LoaderButton>
          <Button
            className='me-auto ms-4'
            variant='link'
            size='sm'
            disabled={saveAndPlaySpinning}
            onClick={() => setSaveAndPlayModalShowing(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );


  // Grid size modal
  const [gridSizeModalShowing, setGridSizeModalShowing] = useState(false);
  const [enteredNumRows, setEnteredNumRows] = useState(charGrid.length);
  const [enteredNumCols, setEnteredNumCols] = useState(charGrid[0].length);
  const numRowsIsValid = isGridDimensionValid(enteredNumRows);
  const numColsIsValid = isGridDimensionValid(enteredNumCols);
  const gridSizeModal = (
    <Modal show={gridSizeModalShowing} onHide={() => setGridSizeModalShowing(false)} onShow={() => {
      setEnteredNumCols(numCols);   // reset the entered num rows/cols whenever the modal is opened
      setEnteredNumRows(numRows);
    }}>
      <Modal.Header className='teal-2-bkgd' closeButton>
        <strong>Configure grid</strong>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <div className='d-flex'>
          <div className='d-flex ms-auto me-4'>
            <FormLabel className='my-auto me-2'>Rows</FormLabel>
            <FormControl
              type='number'
              size='sm'
              disabled={puzzleInteractionStatus === PuzzleInteractionStatus.THINKING}
              value={enteredNumRows}
              isValid={numRowsIsValid}
              isInvalid={!numRowsIsValid}
              onChange={(e) => {
                setEnteredNumRows(e.target.value);
                // Check for validity; submit if valid
                if (isGridDimensionValid(e.target.value)) {
                  const newNumRows = parseInt(e.target.value, 10);
                  changeCharGridDimensions(newNumRows, charGrid[0].length);
                }
              }}
              style={{ width: '5.6rem' }}
            />
          </div>

          <div className='d-flex me-auto'>
            <FormLabel className='my-auto me-2'>Columns</FormLabel>
            <FormControl
              type='number'
              size='sm'
              disabled={puzzleInteractionStatus === PuzzleInteractionStatus.THINKING}
              value={enteredNumCols}
              isValid={numColsIsValid}
              isInvalid={!numColsIsValid}
              onChange={(e) => {
                setEnteredNumCols(e.target.value);
                // Check for validity; submit if valid
                if (isGridDimensionValid(e.target.value)) {
                  const newNumCols = parseInt(e.target.value, 10);
                  changeCharGridDimensions(charGrid.length, newNumCols);
                }
              }}
              style={{ width: '5.6rem' }}
            />
          </div>
        </div>
        <div className='d-flex mt-3'>
          <Button
            className='ms-auto me-2'
            variant='outline-secondary'
            size='sm'
            onClick={() => {
              setEnteredNumRows(21);
              setEnteredNumCols(21);
              changeCharGridDimensions(21, 21);
            }}
          >Sunday (21x21)</Button>
          <Button
            className='me-2'
            variant='outline-secondary'
            size='sm'
            onClick={() => {
              setEnteredNumRows(15);
              setEnteredNumCols(15);
              changeCharGridDimensions(15, 15);
            }}
          >Full (15x15)</Button>
          <Button
            className='me-2'
            variant='outline-secondary'
            size='sm'
            onClick={() => {
              setEnteredNumRows(9);
              setEnteredNumCols(9);
              changeCharGridDimensions(9, 9);
            }}
          >Midi (9x9)</Button>
          <Button
            className='me-auto'
            variant='outline-secondary'
            size='sm'
            onClick={() => {
              setEnteredNumRows(5);
              setEnteredNumCols(5);
              changeCharGridDimensions(5, 5);
            }}
          >Mini (5x5)</Button>
        </div>

        <hr />

        <Form.Check
          className='mx-auto'
          inline
          id='blackouts-preserve-symmetry-switch'
          type='switch'
          label='Enforce grid symmetry'
          checked={puzzleMetadata.get(PuzzleMetadataKey.SYMMETRY_TYPE) === SymmetryType.ROTATIONAL}
          onChange={e => setPuzzleMetavalue(PuzzleMetadataKey.SYMMETRY_TYPE, e.target.checked ? SymmetryType.ROTATIONAL : SymmetryType.NONE)}
        />

        <hr />

        <div className='text-center'>
          <Button
            className='mb-1'
            variant='outline-secondary'
            disabled={puzzleInteractionStatus === PuzzleInteractionStatus.THINKING}
            onClick={() => requestNewBlackouts()}
          >Randomize black blocks</Button>
          <FormCheck
            className='my-2 ms-3 me-auto small text-muted'
            inline
            id='blackouts-preserve-words-switch'
            type='switch'
            label='Randomized blocks preserve existing words'
            checked={getPuzzlePreference(PuzzlePreferenceKey.BLACKOUTS_PRESERVE_WORDS)}
            onChange={e => setPuzzlePreference(PuzzlePreferenceKey.BLACKOUTS_PRESERVE_WORDS, e.target.checked)}
          />
          <div className='text-muted fst-italic small mb-2 balanced-text'>
            Tip: use {new ShortcutDefinition(true, false, 'B').displayString} while in the grid to run through a lot of different block configurations.
          </div>
        </div>

      </Modal.Body>
    </Modal>
  );


  // Prompt on refresh
  useEffect(() => {
    if (!isSaved) {
      window.onbeforeunload = (event) => {
        const suppressSaveWarning = !!localStorage.getItem('suppressSaveWarning');
        localStorage.removeItem('suppressSaveWarning');

        if (!suppressSaveWarning) {
          // Prompt with the dialog box
          const confirmationMessage = 'You are about to lose some unsaved changes. Are you sure you want to leave?';
          event.returnValue = confirmationMessage;
          event.preventDefault();
          return confirmationMessage;
        } else {
          return;   // do not prompt dialog
        }
      }
    } else {
      window.onbeforeunload = undefined;
    }
  }, [isSaved]);


  // Handle key events
  function handleKeyEvent(key, event) {
    /* Note that other (mostly board-related) key events are handled in the ConstructorBoard component. */
    event.preventDefault();

    // Don't do anything if the puzzle doesn't have the right interaction status
    if (puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE) {
      return;
    }

    // Ctrl-G/Cmd-G: bring up the grid configuration modal
    if (key === 'ctrl+g' || key === 'meta+g') {
      setGridSizeModalShowing(b => !b);
    }

    // Ctrl-P/Cmd-P: bring up the export modal
    if (key === 'ctrl+p' || key === 'meta+p') {
      setShowExportModal(b => !b);
    }

    // Ctrl/Cmd-Shift-K: bring up the keyboard shortcuts modal
    if (key === 'ctrl+shift+k' || key === 'meta+shift+k') {
      setKeyboardShortcutsModalShowing(b => !b);
    }

    // Ctrl/Cmd-Shift-S: bring up the Save As modal
    if (key === 'ctrl+shift+s' || key === 'meta+shift+s') {
      setSaveAsModalShowing(b => !b);
    }
  }





  const menuBar = (
    <div
      className='d-flex w-100 purple-1-bkgd'
      style={{position: 'sticky', top: '0', paddingLeft: brandIsShowing ? '70px' : '10px', paddingRight: '60px', paddingTop: '3px', paddingBottom: '4px', zIndex: 100, boxShadow: '0 0 4px black'}}
      {...props}
    >
      <div className='me-auto d-flex'>
        <MenuButton name='File' items={(isAuthenticated ? [] : [
          {
            label: 'Log in to save & share',
            onClick: () => showLoginPage(),
          },
        ]).concat(inLiveMode ? [
          {
            label: 'Go temporarily offline',
            onClick: () => {
              if (window.confirm('Exit live mode? You will have to manually save your changes, and you won\'t see any changes made by other collaborators.')) {
                exitLiveMode();
              }
            },
          },
        ] : isScratch ? [] : [
          {
            label: 'Save',
            shortcutDefinition: new ShortcutDefinition(true, false, 'S'),
            onClick: () => requestPuzzleSave(),
            disabled: !isAuthenticated || isSaved || isSaving || puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE || !puzzleId,
          },
          {
            label: 'Reconnect to live edit',
            onClick: () => enterLiveMode(),
            disabled: !isAuthenticated || puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE || !puzzleId,
          },
        ]).concat([
          {
            label: isScratch && isAuthenticated ? 'Save as...' : 'Duplicate puzzle...',
            shortcutDefinition: new ShortcutDefinition(true, true, 'S'),
            onClick: () => setSaveAsModalShowing(true),
            disabled: !isAuthenticated || isSaving || puzzleInteractionStatus === PuzzleInteractionStatus.THINKING,
          },
          {
            label: 'Export...',
            onClick: () => setShowExportModal(true),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING,
            shortcutDefinition: new ShortcutDefinition(true, false, 'P'),
          },
          {
            label: 'Publish to collection...',
            onClick: () => setShowAddToCollectionModal(true),
            disabled: isScratch,
          },
          {
            label: 'Manage collaborators...',
            onClick: () => setSharePuzzleModalShowing(true),
            disabled: isScratch,
          },
          {
            label: 'Construct Home',
            onClick: () => navigate(isAuthenticated ? '/construct/f' : '/'),
          },
        ])} />

        <MenuButton name='Grid' items={[
          {
            label: 'Undo',
            onClick: () => requestUndo(),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
            shortcutDefinition: new ShortcutDefinition(true, false, 'Z'),
          },
          {
            label: 'Redo',
            onClick: () => requestRedo(),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
            shortcutDefinition: new ShortcutDefinition(true, false, 'Y'),
          },
          { label: 'hr' },
          {
            label: 'Configure grid...',
            onClick: () => setGridSizeModalShowing(true),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
            shortcutDefinition: new ShortcutDefinition(true, false, 'G'),
          },
          {
            label: puzzleMetadata.get(PuzzleMetadataKey.SYMMETRY_TYPE) === SymmetryType.ROTATIONAL ? 'Allow asymmetry' : 'Enforce symmetry',
            onClick: () => setPuzzleMetavalue(PuzzleMetadataKey.SYMMETRY_TYPE, puzzleMetadata.get(PuzzleMetadataKey.SYMMETRY_TYPE) === SymmetryType.ROTATIONAL ? SymmetryType.NONE : SymmetryType.ROTATIONAL),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
          },
          {
            label: outerBlackoutsAreHidden ? 'Show outer blackouts' : 'Hide outer blackouts',
            onClick: () => { outerBlackoutsAreHidden ? removeAllInvisibilityFurnishings() : makeOuterBlackoutsInvisible() },
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
          },
          {
            label: 'Randomize blocks',
            onClick: () => requestNewBlackouts(),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
            shortcutDefinition: new ShortcutDefinition(true, false, 'B'),
          },
          { label: 'hr' },
        ].concat(continuouslyAutofill ? [] : [
          {
            label: autofillStatus === AUTOFILL_STATUS.SEARCHING ? 'Stop searching for a fill' : 'Find a fill',
            onClick: autofillStatus === AUTOFILL_STATUS.SEARCHING ? () => cancelAutofill() : () => requestAutofill(),
            disabled: (
              autofillStatus === AUTOFILL_STATUS.FAILED || 
              autofillStatus === AUTOFILL_STATUS.SUCCEEDED || 
              !charGrid.some(row => row.includes('')) || 
              puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || 
              puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE
            ),
            shortcutDefinition: new ShortcutDefinition(true, false, 'K'),
          },
        ]).concat([
          {
            label: 'Accept suggestions',
            onClick: () => acceptGhostChars(),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE || ghostChars.length === 0,
            shortcutDefinition: new ShortcutDefinition(true, false, '⏎'),
          },
          {
            label: `Turn ${continuouslyAutofill ? 'off' : 'on'} autofiller`,
            onClick: () => continuouslyAutofill ? turnOffContinuousAutofill() : turnOnContinuousAutofill(),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
          },
          { label: 'hr' },
          {
            label: 'Clear letters',
            onClick: () => {
              var locsToChange = [];
              var newChars = [];
              for (let r = 0; r < numRows; ++r) {
                for (let c = 0; c < numCols; ++c) {
                  if (charGrid[r][c] !== '' && charGrid[r][c] !== 'blackout') {
                    locsToChange.push([r, c]);
                    newChars.push('');
                  }
                }
              }
              updateCharGridAtLocs(locsToChange, newChars);
            },
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
          },
          {
            label: 'Clear everything',
            onClick: () => changeCharGridDimensions(numRows, numCols),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.THINKING || puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
          },
        ])} />

        <MenuButton name='Options' items={[
          {
            label: 'Configure warnings colors...',
            onClick: () => setWarningsModalShowing(true),
            disabled: puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE,
          },
          {
            label: 'Keyboard shortcuts...',
            onClick: () => setKeyboardShortcutsModalShowing(true),
            shortcutDefinition: new ShortcutDefinition(true, true, 'K'),
          },
        ]} />
      </div>

      {isScratch ? (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          rootClose
          placement='bottom'
          overlay={<Popover className='text-center pt-3 px-2'>
            <p>
              You're in the Scratch Space, where changes are not saved automatically.
              If you like what you create here, you can always {!isAuthenticated && <><a href='/account' onClick={e => {
                e.preventDefault();
                showLoginPage();
              }}>log in</a> and </>}<strong>Save As</strong>. Otherwise, changes are discarded when you close.
            </p>
          </Popover>}
        >
          <Button
            className='ms-auto my-auto'
            variant='outline-primary'
            size='sm'
            onClick={() => {
              if (isAuthenticated) {
                setSaveAsModalShowing(true);
              } else {
                showLoginPage();
              }
            }}
          >
            <TbTools className='me-2' />
            <span>{isAuthenticated ? (windowWidth >= 600 ? 'Scratch Space. Save As...' : 'Save As...') : (windowWidth >= 500 ? 'Sign up/log in to save' : 'Save As...')}</span>
          </Button>
        </OverlayTrigger>
      ) : (
        <>
          {currentStatusMessage && <div className='me-3 mb-1 mt-auto text-muted fst-italic' style={{fontSize: '12px'}}>
            {currentStatusMessage}
          </div>}

          {!inLiveMode && (
            <OverlayTrigger
              placement='bottom'
              overlay={<Popover className='text-center p-2'>
                {liveModeIsTimedOut ? <>
                  <div>Connection paused due to inactivity. Click anywhere on the board to resume editing.</div>
                </> : <>
                  <div>You are slightly offline. Changes will not be automatically saved. You can still save your work using {new ShortcutDefinition(true, false, 'S').displayString}.</div>
                  <div className='mt-2 fw-bold'>Click to retry connection.</div>
                </>}
              </Popover>}
            >
              <div className='me-3 mb-1 mt-auto zoomable-subtle' style={{ width: '100px', cursor: 'pointer' }} onClick={() => enterLiveMode()}>
                {liveModeIsConnecting ? <Badge bg='secondary'>Connecting...</Badge> : liveModeIsTimedOut ? <Badge bg='warning'>Saving paused</Badge> : <Badge bg='danger'>Not connected</Badge>}
              </div>
            </OverlayTrigger>
          )}

          {inLiveMode && numLiveConnections > 0 && <div className='me-3 my-auto d-flex'>
            {Object.values(liveConnectionInfo).slice(0, numLiveConnections > maxToolbarCollaborators ? maxToolbarCollaborators - 1 : numLiveConnections).map((obj, idx) => (
              <OverlayTrigger
                key={`collaborator-overlay-${idx}`}
                placement='bottom'
                overlay={<Tooltip>{obj.displayName}</Tooltip>}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '26px',
                    height: '26px',
                    borderRadius: '50%',
                    border: `2px solid ${obj.cursorColor}`,
                    backgroundColor: 'white',
                    fontSize: '14px',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >{obj.displayName === '' || obj.displayName.toLowerCase().includes('anonymous') ? '?' : obj.displayName.slice(0, 1)}</div>
              </OverlayTrigger>
            ))}
            {numLiveConnections > maxToolbarCollaborators && (
              <OverlayTrigger
                key={`collaborator-overlay-etc`}
                placement='bottom'
                overlay={<Tooltip>{Object.values(liveConnectionInfo).slice(4).reduce((acc, cur) => acc + '\n' + cur.displayName, Object.values(liveConnectionInfo)[3].displayName)}</Tooltip>}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '26px',
                    height: '26px',
                    borderRadius: '50%',
                    border: `2px solid darkgrey`,
                    backgroundColor: 'white',
                    fontSize: '10px',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >+{numLiveConnections - maxToolbarCollaborators + 1}</div>
              </OverlayTrigger>
            )}
          </div>}

          <div className='d-flex'>
            <Button
              className='me-1 green-button'
              variant='outline-success'
              size='sm'
              onClick={() => {
                if (isSaved) {
                  window.open(`/play/${puzzleId}`, '_blank', 'noreferrer');
                } else {
                  setSaveAndPlayModalShowing(true);
                }
              }}
            >
              <BsBoxArrowUpRight className='me-2' />
              Play
            </Button>

            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement='bottom'
              overlay={<Popover className='p-2 text-center'>Manage sharing and permissions for collaborators</Popover>}
            >
              <Button
                className='blue-button me-1'
                size='sm'
                onClick={() => setSharePuzzleModalShowing(true)}
              >
                <BsPeopleFill className='me-2' />
                Share
              </Button>
            </OverlayTrigger>

          </div>
        </>
      )}
    </div>
  );



  return (
    <>
      <KeyboardEventHandler
        handleKeys={[
          'ctrl+g', 'meta+g',
          'ctrl+p', 'meta+p',
          'ctrl+shift+k', 'meta+shift+k',
          'ctrl+shift+s', 'meta+shift+s',
        ]}
        onKeyEvent={handleKeyEvent}
      />

      {/* <Prompt
        when={!isSaved && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
        message='You have unsaved changes! Are you sure you want to leave?'
      /> */}

      {menuBar}

      {gridSizeModal}
      {warningsModal}
      {keyboardShortcutsModal}
      {saveAsModal}
      {addToCollectionModal}
      {exportModal}
      {saveAndPlayModal}
      {shareModal}
    </>

  );
}